import { useContext, useCallback } from 'react';

import { MESSAGE_TYPES } from '../enums';
import { isCustomError } from '../utilities/error';
import { MessageContext } from '../contexts/message';

const errorMessages = {
  // wrong email or password
  wrongCredentials:
    'Entschuldigung. Leider sind die Accountinformationen falsch. Sind Sie sicher, dass Sie die Email Adresse und das Passwort richtig eingegeben haben? Klicken Sie auf Passwort vergessen, falls Sie Ihr Passwort zurücksetzen wollen.',
  // no user account for this email
  userNotFound:
    'Leider gibt es keinen Account mit dieser Email-Adresse. Bitte überprüfen Sie Ihre Eingaben.',
  // session expired due to expired refresh token
  sessionExpired:
    'Bitte loggen Sie sich erneut ein, Ihre Session ist abgelaufen.',
  // unable to find requested object
  ressourceNotFound:
    'Es tut uns Leid, diese Ressource konnte nicht gefunden werden. Dieser Error wurde reportet, und wir schauen, dass wir dieses Problem beheben. Bitte logge dich erneut ein, oder lade die Seite neu.',
  // bad request, e.g. missing parameters
  badRequest:
    'Es tut uns Leid, leider ist etwas schiefgelaufen. Bitte versuche die Seite neu zu laden. Dieser Error wurde reportet.',
  // signup error: email address already in use
  emailTaken:
    'Die Email-Adresse ist leider schon vergeben. Sind Sie das? Navigieren Sie zu Einloggen, falls Sie bereits einen Account haben, sonst versuchen Sie bitte eine andere Email-Adresse.',
  // the servers don't respond
  serversNotReachable:
    'Entschuldigung. Es schaut so aus, als ob unsere Server zurzeit nicht erreichbar sind, bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
  // client has no internet connection
  noInternetConnection:
    'Entschuldigung. Es schaut so aus, als ob Sie keine Internetverbindung haben. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es noch einmal.',
  // generic fallback message
  generic:
    'Entschuldigung, leider ist etwas schief gelaufen. Dieser Error wurde reportet und wir kümmern uns darum. Bitte versuchen Sie die Seite neu zu laden, vielleicht hilft das bereits!',
};

const useDisplayErrorMessage = () => {
  const { setMessage, setType } = useContext(MessageContext);
  const displayErrorMessage = useCallback(
    error => {
      setType(MESSAGE_TYPES.error);
      if (isCustomError(error)) {
        if (
          !error.type ||
          !Object.keys(errorMessages).find(key => key === error.type)
        ) {
          error.type = 'generic';
        }
        const message = errorMessages[error.type];
        setMessage(message);
      } else {
        setMessage(errorMessages.generic);
      }
    },
    [setMessage, setType]
  );

  return displayErrorMessage;
};

export default useDisplayErrorMessage;
